<template>
  <validation-provider
    :mode="vmode"
    :vid="vid"
    :name="name"
    :rules="isAssistence ? rules : {}"
    v-slot="{ dirty, validated, valid, errors }"
  >
    <b-form-group
      v-if="isAssistence"
      :id="`input-group-${id}`"
      :label="label"
      :label-for="`input-${id}`"
      :class="{
        asterisk:
          isAssistence &&
          (Object.keys(rules).includes('required') ||
            (Object.keys(rules).includes('required_if') && !valid)),
      }"
    >
      <b-form-input
        v-if="controlType === 'text'"
        :id="`input-${id}`"
        :name="`input-${id}`"
        :type="type"
        v-model="inputVal"
        :state="getValidationState({ dirty, validated, valid, errors })"
        :aria-describedby="`input-${id}-live-feedback`"
        :autocomplete="type === 'password' ? 'on' : 'off'"
        @blur="onBlur"
        @input="
          (val) => {
            if (textcase === 'upper') {
              inputVal = val.toUpperCase();
            }
          }
        "
      ></b-form-input>
      <b-form-invalid-feedback :id="`input-${id}-live-feedback`">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      v-else
      :id="`input-group-${id}`"
      :label="label"
      :label-for="`input-${id}`"
    >
      <b-form-input
        v-if="controlType === 'text'"
        :id="`input-${id}`"
        :name="`input-${id}`"
        :type="type"
        v-model="inputVal"
        :state="getValidationState({ dirty, validated, valid, errors })"
        disabled
        :aria-describedby="`input-${id}-live-feedback`"
      ></b-form-input>
      <b-form-invalid-feedback :id="`input-${id}-live-feedback`">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
export default {
  props: {
    value: String,
    vid: String,
    name: String,
    label: String,
    type: {
      default: "text",
      type: String,
    },
    textcase: {
      default: "normal",
      type: String,
    },
    controlType: {
      default: "text",
      type: String,
    },
    isAssistence: {
      default: false,
      type: Boolean,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    vmode: {
      type: String,
      default: "aggressive",
    },
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  // watch: {
  //     value() {
  //         this.$emit('input', this.value);
  //     }
  // },
  methods: {
    getValidationState({ dirty, validated, valid = null, errors = null }) {
      if (errors[0]) return false;
      // return dirty || validated ? valid : null;
      return !Object.keys(this.rules).length
        ? null
        : dirty || validated
        ? valid
        : null;
    },
    onBlur(e) {
      // console.debug("input onBlur", e);
      this.$emit("blur", e);
    },

    hideModal() {
      console.debug("hideModal");
      // this.infoModal.title = "";
      // this.infoModal.content = "";
    },
  },
};
</script>

<template>
  <b-card header="true" header-tag="header" class="mt-2">
    <div
      slot="header"
      class="d-flex justify-content-between align-items-center"
    >
      <p class="mb-0 d-inline-block align-middle">
        <b>{{ header.toUpperCase() }}</b>
      </p>
      <b-btn
        variant="lisaweb"
        size="sm"
        @click="onEdit()"
        :disabled="isEditMode"
        v-if="edit === true"
      >
        Modifica
      </b-btn>
      <b-btn
        variant="lisaweb"
        size="sm"
        @click="shortcut.click()"
        :disabled="isEditMode"
        v-if="shortcut.click"
      >
        {{ shortcut.label }}
      </b-btn>
      <b-btn
        variant="lisaweb"
        size="sm"
        @click="onCreate()"
        v-if="create === true"
      >
        Crea
      </b-btn>
      <!-- <b-btn
        variant="lisaweb"
        size="sm"
        @click="shortcut_replace.click()"
        v-if="replace === true"
      >
        {{ shortcut_replace.label }}
      </b-btn> -->
    </div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-row v-for="(field, index) in fields" :key="index">
        <div class="col-md-4 border-bottom" v-if="hasLabels">
          {{ labels[rep][field.label] }}
        </div>
        <div class="col-md-4 border-bottom" v-else>
          {{ field.label }}
        </div>
        <div class="col-md-8 border-bottom" v-if="!isEditMode && !isCreateMode">
          <!-- <span v-if="!field.isLink">
            {{ texts(field) }}
          </span> -->
          <span v-if="!field.isLink" v-html="texts(field)"></span>
          <span v-else>
            <!-- <span v-if="field.virtual === 'OLD_POLICY'"> -->
            <!-- @click="onLink(link.id, data.number)" -->
            <b-link
              v-for="(link, index) in texts(field)"
              @click="onLink(link.id)"
              :key="index"
              :disabled="!link.id"
            >
              {{ link.caption }}
            </b-link>
            <!-- texts({
                value: 'number',
              }) -->
            <!-- </span> -->
          </span>
        </div>
        <div class="col-md-8" v-if="isCreateMode">
          <base-input
            v-if="
              getType(field) === 'text' && field.value !== 'attributables.NINO'
            "
            :vid="field.value"
            :id="`input-${index}`"
            :name="`${field.label}`"
            :type="field.type || 'text'"
            v-model="form[field.value]"
            :rules="field.rules || {}"
          ></base-input>
        </div>
        <div class="col-md-8" v-if="isEditMode">
          <base-input
            v-if="
              getType(field) === 'text' && field.value !== 'attributables.NINO'
            "
            :vid="field.value"
            :id="`input-${index}`"
            :name="`${field.label}`"
            :type="field.type || 'text'"
            v-model="form[field.value]"
            :rules="field.rules || {}"
          ></base-input>
          <base-dynamic-input
            v-else-if="
              getType(field) === 'text' && field.value === 'attributables.NINO'
            "
            :isAssistence="roles.includes('admin-assistenza')"
            :vid="field.value"
            :id="`input-${index}`"
            :name="`${field.label}`"
            :type="field.type || 'text'"
            v-model="form[field.value]"
            controlType="text"
            textcase="upper"
            :rules="field.rules || {}"
          />

          <base-select
            v-else-if="
              field.model && ['select', 'checkbox'].includes(getType(field))
            "
            label=""
            :vid="field.option"
            :id="`input-2-${index}`"
            :name="`input-2-${index}`"
            :options="options[field.model]"
            v-model="form[field.model]"
            :rules="field.rules || {}"
          ></base-select>

          <base-select
            v-else-if="
              !field.option && ['select', 'checkbox'].includes(getType(field))
            "
            label=""
            :vid="field.value"
            :id="`input-1-${index}`"
            :name="`input-1-${index}`"
            :options="options[field.value]"
            v-model="form[field.value]"
            :rules="field.rules || {}"
          ></base-select>

          <base-select
            v-else-if="
              field.option && ['select', 'checkbox'].includes(getType(field))
            "
            label=""
            :vid="field.option"
            :id="`input-2-${index}`"
            :name="`input-2-${index}`"
            :options="options[field.option]"
            v-model="form[field.option]"
            :rules="field.rules || {}"
          ></base-select>

          <base-datepicker
            v-else-if="getType(field) === 'datepicker'"
            :vid="field.value"
            :id="`input-${index}`"
            :name="`${field.label}`"
            v-model="form[field.value]"
            :rules="field.rules || {}"
          ></base-datepicker>

          <base-textarea
            v-else-if="getType(field) === 'textarea'"
            :vid="field.value"
            :id="`input-${index}`"
            :name="`${field.label}`"
            v-model="form[field.value]"
            :rules="field.rules || {}"
          ></base-textarea>

          <div v-else>Input type {{ getType(field) }} not implemented</div>
        </div>
      </b-row>
      <div class="py-2 mb-4" v-show="isEditMode">
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          @click="handleSubmit(onSave($event))"
          :disabled="!isEditMode"
        >
          Salva
        </b-btn>
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          @click="editable = false"
          :disabled="!isEditMode"
        >
          Annulla
        </b-btn>
      </div>
      <div class="py-2 mb-4" v-show="isCreateMode">
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          @click="handleSubmit(onSubmitCreate($event))"
          :disabled="!create"
        >
          Salva
        </b-btn>
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          @click="creatable = false"
          :disabled="!create"
        >
          Annulla
        </b-btn>
      </div>
    </validation-observer>
  </b-card>
</template>

<script>
import BaseInput from "@/components/form/BaseInput";
import BaseDynamicInput from "@/components/form/BaseDynamicInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseTextarea from "@/components/form/BaseTextarea";
import { getInputType } from "@/utils/forms";
import {
  toLocaleCurrency,
  toLocaleDecimal,
  toLocaleInteger,
} from "@/utils/strings";
import { mapGetters } from "vuex";
import moment from "moment";
moment.locale("it");

export default {
  data() {
    return {
      form: {},
      options: {},
      dataCopy: null,
      editable: false,
      creatable: false,
    };
  },
  props: {
    header: String,
    repository: String,
    edit: {
      type: Boolean,
      default: false,
    },
    create: {
      type: Boolean,
      default: false,
    },
    // replace: {
    //   type: Boolean,
    //   default: false,
    // },
    shortcut: {
      type: Object,
      default() {
        return { label: null, click: null };
      },
    },
    // shortcut_replace: {
    //   type: Object,
    //   default() {
    //     return { label: "", click: null };
    //   },
    // },
    title: String,
    registryId: {
      type: Number,
    },
    addressBookId: {
      type: Number,
    },
    fields: {
      type: Array,
    },
    beForm: {
      type: Object,
    },
    data: {
      type: Object,
    },
    labels: {
      type: Object,
      default() {
        return {};
      },
    },
    fieldsToHideWhenBlank: {
      type: Array,
    },
    fieldsToHide: {
      type: Array,
    },
  },
  components: {
    BaseInput,
    BaseDynamicInput,
    BaseSelect,
    BaseDatepicker,
    BaseTextarea,
  },
  beforeMount() {
    this.dataCopy = Object.assign({}, this.data);
  },
  created() {},
  methods: {
    getInputType,
    prepareForm() {
      let pForm = Object.assign({}, this.form);
      for (const key in pForm) {
        const spl = key.split(".");
        if (spl.length > 1) {
          // not base attribute eg: insurer_id
          let new_key;
          // if field has model prop, use this for submit
          let found = this.fields.find(
            (elem) => elem.value === key || (elem.model && elem.model === key)
          );
          if (found.model) {
            new_key = `attribute_${found.model
              .split(".")
              .slice(1)
              .join(".")}_value`;
          } else {
            new_key = `attribute_${spl.slice(1).join(".")}_value`;
          }

          Object.defineProperty(
            pForm,
            new_key,
            Object.getOwnPropertyDescriptor(this.form, key)
          );
          delete pForm[key];
        }
      }
      return pForm;
    },
    onEdit(e) {
      this.fields.forEach((field) => {
        console.debug("OnEdit: ", e);
        let value;
        let parts = field.value.split(".");
        let length = parts.length;

        if (field.option) {
          value =
            typeof this.dataCopy[field.option] === "object"
              ? this.dataCopy[field.option].value
              : this.dataCopy[field.option];
        } else {
          // if status && relation, extract info from the relation, filtering by status value:
          if (![null, undefined].includes(field.status) && field.relation) {
            // console.debug("onEdit relation!!!!!!!");
            let relationData = this.dataCopy[field.relation];
            let repo = this.repository.split("_").join("");
            let rd = this.getRelationDataByStatus(
              relationData,
              `status_${repo}`,
              field.status
            );
            if (rd) {
              //found relation data for given status
              if (length > 1) {
                value = parts.reduce((acc, part) => acc && acc[part], rd);
              } else {
                value = rd[field.value];
              }
            }
          } else {
            // console.debug("onEdit normal!!!!!!!");
            if (length > 1) {
              value = parts.reduce(
                (acc, part) => acc && acc[part],
                this.dataCopy
              );
            } else {
              value = this.dataCopy[field.value];
            }
          }
        }
        this.setOptions(field);
        let key = field.option || field.model || field.value;
        if (this.options[key]) {
          let options = this.options[key].find((elem) => {
            return elem.text === value || elem.value === value;
          });
          if (![null, undefined].includes(options)) {
            value = options.value;
          }
        }

        if (this.getType(field) == "datepicker" && value) {
          value = value ? value.split("/").reverse().join("-") : "";
          this.form[field.value] = value;
        }
        if (field.option) {
          this.form[field.option] = value;
        } else if (field.model) {
          this.form[field.model] = value;
        } else {
          this.form[field.value] = value;
        }
      });
      this.editable = true;
    },
    onSave(e) {
      console.debug("input onSave", e, this.fields);
      this.editable = false;
      const preparedForm = this.prepareForm();
      // console.debug("preparedForm");
      // console.debug(preparedForm);
      this.$emit(
        "update",
        preparedForm,
        this.repository,
        this.title,
        this.addressBookId
      );
    },
    onCreate() {
      this.creatable = true;
    },
    onSubmitCreate() {
      let payload = {};
      if (this.repository == "address_book") {
        const status_addressbook = this.fields[0].status;
        this.$set(payload, `status_addressbook`, status_addressbook);
        this.$set(payload, `registry`, {
          [this.registryId]: {},
        });
        for (const [key, value] of Object.entries(this.form)) {
          for (const field of this.fields) {
            if (key === field.value) {
              const spl = key.split(".");
              this.$set(payload, `attribute_${spl[1]}_value`, value);
            }
          }
        }
        this.$emit("create", payload, this.repository, this.title);
        this.creatable = false;
      }
    },
    /**
     * Set a value based on a dot-notation key.
     *
     * @param obj
     * @param keys
     * @param value
     */
    setValue(obj, keys, value) {
      keys = typeof keys === "string" ? keys.split(".") : keys;
      const key = keys.shift();
      if (keys.length === 0) {
        obj[key] = value;
        return;
      } else if (!Object.hasOwnProperty.call(obj, key)) {
        obj[key] = {};
      }
      this.setValue(obj[key], keys, value);
    },
    getRelationDataByStatus(data, statusName, statusValue) {
      return data
        ? data.filter((elem) => {
            return elem[statusName].value == statusValue;
          })[0]
        : null;
    },
    getRelationIndexByStatus(data, statusName, statusValue) {
      return data
        ? data.findIndex((elem) => {
            return elem[statusName].value == statusValue;
          })
        : -1;
    },
    getType(field) {
      let type = "text";
      let f =
        this.beForm[this.repository][field.label] ||
        this.beForm[this.repository][`attribute_${field.label}_value`];
      if (f) {
        type = this.getInputType(f);
      }
      return type;
    },
    setOptions(field) {
      // console.info("setOptions");
      let type = "text";
      let f;
      if (this.beForm[this.repository]) {
        f =
          this.beForm[this.repository][field.option] ||
          this.beForm[this.repository][field.label] ||
          this.beForm[this.repository][`attribute_${field.label}_value`];
      }
      if (f) {
        type = this.getInputType(f);
      }
      if (["select", "checkbox"].includes(type)) {
        let key = field.option || field.model || field.value;
        this.options[key] = f.options;
        this.form[key] = f.value;
      }
    },
    onLink(id) {
      console.debug(`onLink id: ${id}`);
      this.$emit("modal", id);
    },
    ...mapGetters("auth", ["provinceByCouncil", "countryByCouncil"]),
  },
  computed: {
    ...mapGetters("auth", ["roles"]),
    rep() {
      return this.repository;
    },
    isEditMode() {
      return this.editable;
    },
    isCreateMode() {
      return this.creatable;
    },
    texts() {
      return (field) => {
        if (!field.value && !field.virtual) {
          console.info(`missing field.value or field.virtual`);
          console.debug(field);
          return "";
        }
        let value;
        let relationData;
        let parts = [];
        // c'è l'attributo "virtual", e non è stato (giustamente) valorizzato l'attributo "value": split va in errore
        if (field.value) {
          parts = field.value.split(".");
        }
        let length = parts.length;
        if (field.option) {
          value =
            typeof this.dataCopy[field.option] === "object"
              ? this.dataCopy[field.option].text
              : parts.reduce((acc, part) => acc && acc[part], this.dataCopy);
        } else {
          // if status && relation, extract info from the relation, filtering by status value:
          // handle "dot notation" relation
          if (field.status != null && field.relation) {
            let relationData;
            let partsR = field.relation.split(".");
            if (partsR.length > 1) {
              relationData = partsR.reduce(
                (acc, part) => acc && acc[part],
                this.dataCopy
              );
            } else {
              relationData = this.dataCopy[field.relation];
            }
            let repo = this.repository.split("_").join("");
            let rd = this.getRelationDataByStatus(
              relationData,
              `status_${repo}`,
              field.status
            );
            if (rd) {
              //found relation data for given status
              if (length > 1) {
                value = parts.reduce((acc, part) => acc && acc[part], rd);
              } else {
                value = rd[field.value];
              }
            }
          } else if (
            field.virtual &&
            !(field.virtual instanceof Function) &&
            field.relation
          ) {
            switch (field.virtual) {
              case "NEW_POLICIES":
                // Array
                value = []; // restituisco un array di number
                relationData = this.dataCopy[field.relation];
                if (relationData && relationData.length) {
                  for (const p of relationData) {
                    value.push({
                      id: p.id,
                      caption: p.number,
                    });
                  }
                } else {
                  value.push({
                    id: null,
                    caption: "--",
                  });
                }
                break;
              case "OLD_POLICY":
                // Object
                value = []; // restituisco un array di number
                relationData = this.dataCopy[field.relation];
                if (relationData && relationData.id) {
                  value.push({
                    id: relationData.id,
                    caption: relationData.number,
                  });
                } else {
                  value.push({
                    id: null,
                    caption: "--",
                  });
                }
                break;
            }
          } else if (field.virtual && field.virtual instanceof Function) {
            // è una function...
            value = field.virtual(this.dataCopy);
          } else {
            if (length > 1) {
              value = parts.reduce(
                (acc, part) => acc && acc[part],
                this.dataCopy
              );
            } else {
              value = this.dataCopy[field.value];
            }
          }
        }
        // if (field.type === "currency") {
        //   return toLocaleCurrency(value);
        // } else if (field.type === "datepicker" && value) {
        //   return moment(value).format("DD/MM/YYYY");
        // }
        if (Object.hasOwnProperty.call(field, "type")) {
          // 99% è un customattribute (a meno di altro contesto oltre a questo che lo imposterà)
          // REVIEW: nei casi futuribili, non è detto che si debba fare "return", quindi andrebbe rivisto
          switch (field.type) {
            case "currency":
              return toLocaleCurrency(parseFloat(value));
            // break;
            case "datepicker":
              return value ? moment(value).format("DD/MM/YYYY") : null;
            // break;
            case "decimal":
              return toLocaleDecimal(value);
            // break;
            case "number":
              return toLocaleInteger(value);
            // break;
            case "radio":
              // ATTENZIONE che in realtà è nato unicamente come "scelta SI/NO"
              return value == "Y" ? "SI" : "NO";
            // break;
            default:
              break;
          }
        }
        // handle specific cases here
        let found;
        // get just the last 2 elements from split
        if (value != undefined) {
          switch (field.value.split(".").slice(-2).join(".")) {
            case "attributables.NOB":
              // non mostro nulla se disegnao NATION2 e NOB è Italia
              found = this.$store.state.auth.countries.find(
                (e) => e.title == "Italia"
              );
              if (field.virtual == "NATION2" && value == found.id) {
                value = "";
                break;
              }
              found = this.$store.state.auth.countries.find(
                (e) => e.id == value
              );
              value = found ? found.title : "";
              break;
            case "attributables.NOR":
              found = this.$store.state.auth.countries.find(
                (e) => e.id == value
              );
              value = found ? found.title : "";
              break;
            case "attributables.POB":
            case "attributables.CITY":
            case "attributables.ISPL":
              switch (field.virtual) {
                case "PROVINCE":
                  found = this.$store.state.auth.councils.find(
                    (e) => e.code == "ZZ"
                  );
                  if (value != found.id) {
                    value = this.provinceByCouncil()(value, "code");
                  } else {
                    value = "";
                  }
                  break;
                case "NATION":
                  found = this.$store.state.auth.councils.find(
                    (e) => e.code == "ZZ"
                  );
                  if (value != found.id) {
                    value = this.countryByCouncil()(value, "title");
                  } else {
                    value = "";
                  }
                  break;
                // case "NATION2":
                //   // non mostro nulla se NOB è Italia
                //   found = this.$store.state.auth.countries.find(
                //     (e) => e.title == "Italia"
                //   );
                //   if (value == found.id) {
                //     value = "";
                //   }
                //   break;
                default:
                  // se POB ed è "Comune estero", non mostrare nulla
                  if (
                    field.value.split(".").slice(-2).join(".") ===
                    "attributables.POB"
                  ) {
                    found = this.$store.state.auth.councils.find(
                      (e) => e.code == "ZZ"
                    );
                    if (value == found.id) {
                      value = "";
                      break;
                    }
                  }
                  found = this.$store.state.auth.councils.find(
                    (e) => e.id == value
                  );
                  value = found ? found.title : "";
                  break;
              }
              break;
            default:
              break;
          }
        }
        return value;
      };
    },
    hasLabels() {
      return Object.keys(this.labels).length && this.labels[this.rep];
    },
  },
};
</script>
<style scoped>
.card {
  border: none;
}
.col-md-4.border-bottom {
  text-transform: uppercase;
  color: rgb(63, 60, 60);
  font-size: bold;
}
#informazioni {
  margin-right: 560px;
}
</style>
